.cart{
    color:#fff;
    padding: 10px;
    background-color: #000;
    border-radius: 50%;
    opacity: 0.6;
  }
  .discount{
    padding: 10px;
    font-weight: 500;
    color:#e3c230;
    font-size: 20px;
  }