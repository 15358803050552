header{
    width: 100%;
    height: auto;
    position: fixed;
    color:#fff;
    z-index: 100;
    top: 0;
}

.head-color{
    background-color: rgb(75, 15, 93);
    background-image: linear-gradient(135deg,rgb(75, 15, 93) 10%,#1f0726);
    box-shadow: 0 10px 40px 8px rgba(0,0,0,.25);
    animation: 30ms ease-in-out 0s ;
}

nav ul{
    list-style: none;
    display: flex;
    justify-content: flex-end;
    text-transform: uppercase;
    font-size: 12px;
    height: 30px;
    padding-top: 15px;
    letter-spacing: 1.2px;
}

nav ul li a {
    display: flex;
    color: #fff;
    text-decoration: none;
    position: relative; /* Ensure that ::after is positioned relative to the link */
  }
  
nav ul li a::after {
    content: '';
    position: absolute;
    width: 0%;
    height: 1.5px; /* Adjust height for underline thickness */
    bottom: -3px; /* Position it at the bottom of the link */
    left: 10%;
    background-color: #e3c42c;
    transition: width 0.3s ease-in-out;
  }

  nav li a:hover::after{
    width: 80%; /* Adjust this value to control how much the underline expands */
  }

.divider{
    height: 20px;
    border-left: "1px solid #fff";
    background-color: #fff;
    width: 1px;
    margin-left: 12px;
    margin-bottom: 30px;
}

nav ul li{
    text-wrap: nowrap;
    word-spacing: 1px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 7px;
}

.search{
    background-color: transparent;
    outline: none;
    border: none;
    color:#fff;
    border-bottom: 1.4px solid #fff;
    padding-left: 30px;
    border-radius: 7px;
    padding-bottom: 5px;
}

.search:hover, .search:focus{
    border-bottom-color: #e3c42c;
}
.search-icon {
    position: absolute;
    width: 15px;
    margin-left: 10px;
    margin-top: 2px;
}

.logo{
    padding-top: 15px;
}

.scroll{
    background-color: #662678;
    position: fixed;
    padding:12px 12px;
    right: -70px;
    bottom: 20px;
    border-radius: 50%;
    cursor: pointer;
    transition: all 1s ease;
    transform: rotate(180deg);
  }
  
  .scrolled{
    right:20px !important;
    animation: 30ms ease-in-out 0s ;
  }

  .base-footer{
    padding: 10px 10px;
    border-top: 1px solid #833C99;
    margin-top: 20px;
    text-align: center;
  }
  .box{
    min-height: 200px;
    margin-left: 20px;
  }
  .search::-webkit-input-placeholder {
    color: #fff;
    opacity: 1;
    font-size: 12px;
  }

  footer{
    width: 100%;
    background-image: linear-gradient(135deg,rgb(75, 15, 93) 10%,#1f0726);
    color:#f5f4f5;
    font-size: 13px;
    padding:0 10px;
    padding-top: 80px;
    min-height: 550px;
    font-style: italic;
    letter-spacing: .1em;
    margin-top: 50px;
  }
footer h4{
  color: #f1f1f1;
  padding-bottom: 20px;
  display: flex;
}
footer label{
  color: #e3c42c;
  font-size: 12px;

}
footer ul{
  list-style: none;
  padding-left: 0;
}
footer ul li{
  padding-bottom: 10px;
}
footer .row{
  flex-direction: column;
}

.logo-footer{
  margin-bottom: 20px;
}
footer h4::after {
  content: '';
  position: absolute;
  width: 8%;
  height: 1px;
  background-color: #e3c42c;
  margin-left: 1%;
  transition: width 0.3s ease-in-out;
  margin-top: 35px;
}
footer span{
  color: #e3c42c;
}

footer label span{
  color: red;
  margin-left: 2px;
}
footer input,footer textarea{
  background-color: transparent !important;
  border: none !important;
  border-bottom: 1px solid #fff !important;
  color:#fff !important;
  font-size: 14px;
}

footer input:focus,footer textarea:focus{
   border:1px solid #e3c42c;
}

header .user{
  padding-top: 0;
  cursor: pointer;
}
.essentials{
  padding-top: 20px;
}
.user ul{
  position: absolute;
  background-color: #f1f1f1;
  padding: 20px;
  height: auto;
  right: 5%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  color:#662678;
  max-width: 130px;
}
.advert{
  display:none;
}
.user .dropdown-closed{
  visibility: hidden;
}
.user ul li{
  padding-bottom: 10px;
  color:#662678

}
.user ul li a{
  color:#662678
}
.user span{
  margin-right: 10px;
}
footer .form-row{
  padding-left: 0;
}
.menu{
  position: absolute;
  top: 20px;
  right: 15px;
  height: 40px;
  width:45px;
  background: transparent;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.menu span{
  width: 35px;
  border: 1px solid #e3c42c;
  margin-bottom: 8px;
}
.nav-list-mobile{
  display: flex !important;
}
nav{
  display: flex;
  min-height: 15px;
  justify-content: space-between;
}
nav ul{
    flex-direction: column;
    height:auto;
    width:100%;
    display: none;
    transition: all 1s ease;
    padding-left: 0;
}
.login-form{
  margin-left:0 !important
}
.login-area{
  padding:30px
}

nav ul a{
   justify-content: center;
   padding-bottom: 5px;
}
.col-md-3, .col-md-4,.col-md-5, .col-md-7{
  width:100% !important
}
/* Larger than mobile */
@media (min-width: 400px) {
  .menu{
    display: flex;
  }
  .advert{
    display:none
  }
  .menu-gap{
    display: flex;
    justify-content: space-between;
  }
  .prop-section{
    flex-direction: column;
  }

  .login-form{
    margin-left:0 !important
  }

  .service-scroll{
    max-width: 70%;
  }
 
  .col-md-4,.col-md-3, .col-md-5, .col-md-7{
    width:100% !important;
    margin-left: 0;
    margin-bottom: 10px;
  }
}

/* Larger than phablet (also point when grid becomes active) */
@media (min-width: 550px) {
  .menu{
    display: flex;
  }
  .menu-gap{
    display: flex;
    justify-content: space-between;
  }
 
  header{
    min-height:60px
  }
  .prop-section{
    flex-direction: column !important;
  }
  nav ul{
    margin-left: 0;
    padding-left: 0;
  }
  .service-scroll{
    max-width: 70%;
  }
  .login-form{
    margin-left:20% !important
  }
  footer .row{
    flex-direction: row;
  }
  .col-md-4{
    width:31.5% !important
  }
  .col-md-3 {
    width: 23.2% !important;
  }
  .col-md-5{
    width: 41.66666667%;
  }
  .col-md-7{
    width: 58.33333333% !important; 
  }
  .advert{
    display:block
  }
  
}
/* Larger than tablet */
@media (min-width: 750px) {
  .menu{
    display: flex;
  }
  .menu-gap{
    display: flex;
    justify-content: space-between;
  }
  .service-scroll{
    max-width: 70%;
  }
  nav ul{
    margin-left: 0;
    padding-left: 0;
  }
  .prop-section{
    flex-direction: row !important;
  }
  footer .row{
    flex-direction: row;
  }
  .col-md-4{
    width:31.5% !important
  }
  .col-md-3 {
    width: 23.2% !important;
  }
  .col-md-5{
    width: 41.66666667% !important;
  }
  .col-md-7{
    width: 58.33333333% !important; 
  }
  .advert{
    display:block
  }
}

/* Larger than desktop */
@media (min-width: 1000px) {
  .menu{
    display: none;
  }
  .service-scroll{
    max-width: 90%;
  }
  nav ul{
    flex-direction: row;
    margin-left: 0;
    padding-left: 0;
    display: flex;
  }
  .center-card {
    transform: scale(1.1) !important; /* Make the center card larger */
  }
  .left-card {
    transform: scale(0.8) translateX(-130%) !important; /* Make the left card smaller and move it to the left */
  }  
  .right-card {
    transform: scale(0.8) translateX(130%) !important; /* Make the left card smaller and move it to the left */
  }
  header{
    height:70px
  }
  footer .row{
    flex-direction: row;
  }
  .col-md-4{
    width:31.5% !important
  }
  .col-md-3 {
    width: 23.2% !important;
  }
  .col-md-5{
    width: 41.66666667% !important;
  }
  .col-md-7{
    width: 58.33333333% !important; 
  }
  .advert{
    display:block
  }
}

/* Larger than Desktop HD */
@media (min-width: 1200px) {
  .menu{
    display: none;
  }
  .service-scroll{
    max-width: 90%;
  }
  .center-card {
    transform: scale(1.1) !important; /* Make the center card larger */
  }
  .left-card {
    transform: scale(0.8) translateX(-130%) !important; /* Make the left card smaller and move it to the left */
  }  
  .right-card {
    transform: scale(0.8) translateX(130%) !important; /* Make the left card smaller and move it to the left */
  }
    nav ul{
    flex-direction: row;
    margin-left: 0;
    padding-left: 0;
    display: flex;
  }
  footer .row{
    flex-direction: row;
  }
  header{
    height:70px
  }
  .advert{
    display:block
  }
  .col-md-4{
    width:31.5% !important
  }
  .col-md-3 {
    width: 23.2% !important;
  }
  .col-md-5{
    width: 41.66666667% !important;
  }
  .col-md-7{
    width: 58.33333333% !important; 
  }

}
