.sidebar{
    min-height: 500px;
    max-height: 800px;
    border-right:1px solid #ccc;
    margin-top: 30px;
    background-color: #fff;
    padding: 25px;
    border-radius: 5px;
    margin-bottom: 20px;
}
.filter-type{
    width: 100%;
    margin: 0;
    margin-bottom: 10px;
}

.filter-type select, .filter-type input{
    width: 100%;
    border: 1px solid #833C99;
    font-size: 13px;
    padding: 5px;
    border-radius: 5px;
}
.filter-type label{
   font-size: 14px;
   margin-bottom: 5px;
}
.verified{
    margin-bottom: 20px;
}
.verified h4:after{
    content: '';
    width: 60%;
    height: 2px; /* Adjust height for underline thickness */
    bottom: 0; /* Position it at the bottom of the link */
    left: 20%;
    position: relative;
    background-color: #e3c42c;
    transition: width 0.3s ease-in-out;
    display: block;
    margin-top: 10px;
    margin-bottom: 30px;
}