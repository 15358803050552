.payment-card {
  transition: transform 0.2s;
}

.payment-card:hover {
  transform: translateY(-5px);
}

.custom-header {
  background: linear-gradient(135deg, var(--primary-color) 0%, #9b4eb8 100%);
  color: white;
  margin-top: 96px;
}

.payment-history-item {
  border-left: 4px solid var(--primary-color);
  margin-bottom: 10px;
  padding: 10px;
  background-color: white;
}

.status-badge {
  border-radius: 20px;
  padding: 5px 15px;
  font-size: 0.8rem;
  font-weight: 600;
}

.status-pending {
  background-color: var(--secondary-color);
  color: #000;
}

.status-completed {
  background-color: var(--primary-color);
  color: white;
}

.nav-tabs .nav-link {
  color: #6c757d;
}

.nav-tabs .nav-link.active {
  background-color: var(--primary-color);
  color: white;
  border-color: var(--primary-color);
}

.payment-tab {
  padding: 8px 16px;
  border-radius: 6px;
  margin-right: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  transition: all 0.2s;
}

.payment-tab.active {
  background-color: var(--primary-color);
  color: white;
  border-color: var(--primary-color);
}

.tab-pane.active {
  background-color: #fff !important;
  border-color: #f1f1f1 !important;
}

.payment-tab:not(.active) {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
}

.btn-primary {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.btn-primary:hover {
  opacity: 0.9;
}

/* Add these styles to your existing CSS file */

/* Bulk Actions Toolbar */
.bulk-actions {
  padding: 10px;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  margin-bottom: 16px;
}

.bulk-actions .btn {
  margin-right: 8px;
}

/* Filters */
.filters {
  margin-bottom: 16px;
}

.filters input {
  margin-right: 8px;
  margin-bottom: 8px;
}

.filters input[type="text"],
.filters input[type="date"] {
  padding: 8px;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  width: 200px;
}

.filters input[type="text"]:focus,
.filters input[type="date"]:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(41, 128, 185, 0.2);
}

/* Table Checkboxes */
.table th input[type="checkbox"],
.table td input[type="checkbox"] {
  margin: 0;
  cursor: pointer;
}

/* Responsive Filters */
@media (max-width: 768px) {
  .filters input {
    width: 100%;
  }
}
