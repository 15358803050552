.service-provider {
    background-color: #833C99;
    padding-bottom: 100px;
  }

  .stage-list {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    gap: 10px; /* Space between items */
  }
  .stage-scroll{
    overflow: hidden;
  }
  .card-boda h5{
    text-align: center;
    padding-top: 5px;
    font-weight: 500;
  }
  .stage-list li {
    white-space: nowrap; /* Prevent text wrapping */
    padding: 7px 20px;
    background-color: #f8f9fa;
    border-radius: 5px;
    cursor: pointer;
    
  }
  
  .stage-list li.active {
    background-color: #833C99;
    color: white;
  }
  .services{
    padding: 20px;
  }
  .details-btn{
    max-width: 25%;
  }
  .service-list {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    gap: 10px; /* Space between items */
  }
  .service-scroll{
    overflow: hidden;
    padding-bottom: 20px;
    max-width: 70%;
  }
  .service-list li {
    white-space: nowrap; /* Prevent text wrapping */
    padding: 25px;
    width: auto;
    height: 80px;
    background-color: #000;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    margin-left: 20px;
    background-image:  linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url("../../assets/images/AfricaFood.jpg");
    background-size: 100%;
  }
  
  .service-list li.active,.service-list li:hover {
    border: 1px solid #f1f1f1 !important;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 3px 3px 0px rgba(0, 0, 0, 0.14), 0px 3px 6px 0px rgba(0, 0, 0, 0.12);
    color: white;
  }
  .services-type{
    display: flex;
    list-style: none;
    justify-content: center;
  }

  .services-type .active{
    background-color: #cbad2d !important;
    font-weight: 500;
    color:#fff !important;
  }
  .view-more-y{
    background-color: #cbad2d;
  }
  .services-type li{
    padding: 10px 20px;
    color:#fff;
    background-color: transparent;
    border:1px solid #fff;
    margin-left: 10px;
    border-radius: 10px;
  }

  .services-type li:hover{
    border:1px solid #cbad2d;
    color:#cbad2d;
    cursor: pointer;
  }