/* Styling for the index.js */
.card-body {
    display: flex;
    flex-direction: column; 
    justify-content: space-between;
    padding: 20px;
    
  }

  .card-img {
    object-fit: cover; 
    height: 185px;
  }
  

  /* Styling for the View.js */
  .activity-image {
  width: 100%;
  height: 350px;
  object-fit: cover;
  border-radius: 12px;
}

.details-box {
  background: #fff;
  border-left: 5px solid rgb(75, 15, 93);
}


.activity-title {
  font-size: 28px;
  font-weight: bold;
  color: #333;
}

.activity-dates {
  font-size: 16px;
  color: #666;
  margin-bottom: 15px;
}

.activity-description {
  font-size: 18px;
  line-height: 1.6;
  color: #444;
}

