.page-title{
    background-color: #000;
    padding-top: 120px;
    height: 300px;
    background-size: cover;
    background-position: 40%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url("../../assets/images/assetsTwo.jpg");
  }
.intro h2{
    width: 100%;
    text-align: center;
    font-weight: 500;
    color:#fff;
  }
  .intro p{
    text-align: center;
    font-size: 18px;
     padding-bottom: 20px;
     text-transform: capitalize;
  }
 
  .intro p{
    color: #fff;
  }
  .intro h2:after{
      content: '';
      width: 20%;
      height: 2px; /* Adjust height for underline thickness */
      bottom: 0; /* Position it at the bottom of the link */
      left: 40%;
      position: relative;
      background-color: #e3c42c;
      transition: width 0.3s ease-in-out;
      display: block;
      margin-top: 10px;
  }