:root {
  --primary-color: #833c99;
  --secondary-color: #cbad2d;
}

.social-media li{
  padding:0 7px !important
}
.rotator{
  width: 100%;
  min-height: 100vh;
  background-size: cover;
  background-position: 50% 50%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) );
  position: relative;
}
.rotator img{
  width: 100%;
}

.btn-outline-primary{
  font-size: 14px;
}
.essentials{
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}

.down-card{
  margin-top: 50px;
}

.arrow-nav{
  background-color: #fff;
  height: 70px;
  width:70px;
  cursor: pointer;
  border-radius: 50%;
  margin-top: 20%;
  padding-top: 13px;
  padding-left: 15px;
  padding-right: 10px;
  box-shadow: 0 10px 40px 8px rgba(0,0,0,.25);
}
.arrow-left{
  color:#000;
  transform: rotate(225deg);
}
.arrow-right{
  color:#000;
  transform: rotate(135deg);
  margin-right: 7%;
}
.intro-text{
  position: absolute;
  padding-top: 2.3%;
  color: #fff;
  font-weight: bold;
  margin-top: 100px;
}

.intro-text h1{
  width: 100%;
  font-size: 60px;
  text-align: center;
  font-weight: 400;
}

.intro-text h1:after{
    content: '';
    width: 30%;
    height: 2px; /* Adjust height for underline thickness */
    bottom: 0; /* Position it at the bottom of the link */
    left: 35%;
    position: relative;
    background-color: #cbad2d;
    transition: width 0.3s ease-in-out;
    display: block;
    margin-top: 12px;
}


.boda-stages .page-intro h3{
  width: 100%;
  text-align: center;
  font-weight: 500;
  color:#000;
  margin-top: 150px;
}

.service-provider .page-intro h3{
  width: 100%;
  text-align: center;
  font-weight: 500;
  color:#fff;
  margin-top: 90px;
}

.page-intro h3{
  width: 100%;
  text-align: center;
  font-weight: 500;
  color:#000;
  margin-top: 90px;
}
.page-intro p{
  text-align: center;
  font-size: 18px;
   padding-bottom: 20px;
   text-transform: capitalize;
}
.service-provider .page-intro p{
  color: #fff;
}
.boda-stages .page-intro p{
  color: #833C99;
}
.page-intro h3:after{
    content: '';
    width: 20%;
    height: 2px; /* Adjust height for underline thickness */
    bottom: 0; /* Position it at the bottom of the link */
    left: 40%;
    position: relative;
    background-color: #cbad2d;
    transition: width 0.3s ease-in-out;
    display: block;
    margin-top: 10px;
}

main{
  min-height: 500px;
}


.stage-scroll ul{
  list-style: none;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
.stage-scroll ul li{
   padding: 10px 30px;
   border-radius: 30px;
   font-size: 14px;
   box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.stage-scroll ul li:hover{
  background-color: #833C99;
  color: #fff;
  cursor: pointer;
}
.btn-arrow{
  background-color: #833C99;
  outline: none;
  height: 40px;
  padding-bottom: 5px;
  border: none;
  width: 40px;
  border-radius: 5px;
  margin-top: 4px;
}
.left-arrow{
  transform: rotate(90deg);
}
.right-arrow{
  transform: rotate(270deg);
}
.active{
  background-color: #833C99;
  color: #fff;
}
.bodas{
  display: flex;
  margin-top: 10px;
  margin-bottom: 30px;
  flex-wrap: wrap;
}

.btn-primary {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.btn-primary:hover {
  background-color: #9b4eb8 !important;
  border-color: #9b4eb8 !important;
}

.btn-outline-primary {
  color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.btn-outline-primary:hover {
  color: white !important;
  background-color: var(--primary-color) !important;
}

.bg-primary {
  background-color: var(--primary-color) !important;
}

.text-primary {
  color: var(--primary-color) !important;
}

.badge.bg-primary {
  background-color: var(--secondary-color) !important;
  color: #000 !important;
}

.btn-secondary {
  background-color: #cbad2d !important;
  border: none;
}

.btn-secondary:hover {
  background-color: #d8b92d !important;
  border: none;
}

.btn-outline-secondary  {
  border-color: #cbad2d;
}

.btn-outline-secondary:hover {
  border: 1px solid #cbad2d !important;
  color: #fff;
  background-color: #cbad2d !important;;

}


.page-link {
  position: relative;
  display: block;
  color: var(--primary-color);
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}


.active > .page-link, .page-link.active {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.page-item .active {
  border: none;
}