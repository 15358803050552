/* Header Styles */
.head-color {
  background-color: rgb(75, 15, 93);
  background-image: linear-gradient(135deg, rgb(75, 15, 93) 10%, #1f0726);
  box-shadow: 0 10px 40px 8px rgba(0, 0, 0, 0.25);
  animation: 30ms ease-in-out 0s;
}

.navbar {
  transition: background-color 0.3s ease;
}

.nav-icon span {
  display: none;
}

.nav-icon:hover {
  background-color: transparent;
  padding: 2px 4px;
  border-radius: 4px;
}

.nav-icon .nav-link::after {
  content: "";
  position: absolute;
  top: 0;
  width: 0;
  height: 12px;
  background-color: #e3c42c;
  transition: all 0.3s ease;
  transform: translateX(-50%);
}

.navbar-toggler {
  border: none;
  padding: 0;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.header .nav-link {
  position: relative;
  padding: 0.5rem 1rem;
  transition: color 0.3s ease;
  text-transform: uppercase;
  font-size: 14px;
  height: 30px;
  /* padding-top: 6px; */
  letter-spacing: 1.2px;
  color: #fff;
}

.header .nav-link::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: #e3c42c;
  transition: all 0.3s ease;
  transform: translateX(-50%);
}

.header .nav-link:hover::after {
  width: 100%;
}

/* Scroll Top Button */
.scroll-top-btn {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  background-color: rgb(75, 15, 93, 0.8);
  color: white;
  border: none;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 1000;
}

.scroll-top-btn:hover {
  transform: translateY(-4px);
}

/* Rotator Styles */
.rotator {
  position: relative;
  background-attachment: fixed;
  color: white;
}

@media (max-width: 768px) {
  .rotator {
    background-attachment: scroll;
    min-height: 70vh;
  }


  .navbar-collapse {
    background-color: rgb(75, 15, 93, 0.95);
    padding: 1rem;
    border-radius: 8px;
    margin-top: 1rem;
  }

  .nav-link::after {
    display: none;
  }

  .nav-link {
    padding: 0.75rem 1rem;
    border-radius: 4px;
  }

  .nav-link:hover {
    background-color: transparent;
  }

  .header {
    padding: 0.5rem 1.5rem;
  }
}

/* Tablet and mobile: Show the text next to the icon */
@media (max-width: 991.98px) {
  .nav-icon {
    display: flex;
    align-items: center;
  }
  .nav-icon span {
    display: inline-block;
    font-size: 1rem;
    color: white;
  }
}

@media (max-width: 575px) {
  .navbar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1050;
  }
}

