.login {
  display: flex;
  min-height: 100vh;
  background-color: #f1f1f1;
}

.login-area {
  background-color: #f1f1f1;
  margin-bottom: 20px;
}

.form-row {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
}

.login-form {
  text-transform: capitalize;
  min-height: 300px;
  background-color: #fff;
  padding: 30px 30px;
  box-shadow: 0 5px 20px 5px rgba(0, 0, 0, 0.15);
  margin-left: 20%;
  margin-top: 12%;
}
.login-form a {
  text-decoration: none;
  color: #833c99;
}
.login-form input,
.login-form select {
  background-color: transparent;
  border: 1px solid #833c99;
  height: 42px;
  margin-bottom: 10px;
  font-size: 13px;
}
input:focus {
  border: 1px solid rgb(75, 15, 93) !important;
  outline: none;
}
.login-form h4 {
  text-align: center;
  margin-bottom: 40px;
  margin-top: 20px;
}

.login-form label span {
  color: rgb(254, 42, 42);
  padding: 5px;
}
.advert {
  height: auto;
  padding: 2%;
  background-image: linear-gradient(135deg, rgb(75, 15, 93) 10%, #1f0726);
  margin-bottom: 0;
}
.promise {
  color: #f1f1f1;
  padding: 20px;
  font-style: italic;
  text-align: center;
  font-size: 21px;
  margin-top: 20px;
}

.promise h5 {
  color: #ffbe00;
}

.forgot {
  display: flex;
  justify-content: space-between;
}

.forgot a {
  color: #833c99;
  text-decoration: none;
  font-size: 13px;
}

.login-form label {
  position: absolute;
  text-align: left;
  margin-left: 10px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 13px;
  background-color: #fff;
  color: #1f0726;
  margin-top: -8px;
}

.eye-closed,
.eye-open {
  color: #ffbe00;
  cursor: pointer;
  position: absolute;
  right: 18vw;
  margin-top: -43px;
}

.eye-icon {
  width: 1em;
  height: 1em;
}

.reviews {
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-top: 20%;
}

.reviews .review-slider {
  display: flex;
  transition: transform 1s ease-in-out;
}

.reviews ul {
  list-style: none;
  padding-left: 0;
  display: flex;
  margin: 0;
  transition: transform 1s ease-in-out; /* Smooth scrolling */
}

.reviews ul li {
  color: #ffbe00;
  display: flex;
  font-style: italic;
  font-size: 14px;
  flex-shrink: 0;
  width: 60%; /* Adjust this to fit the container */
  padding: 0 20px;
  box-sizing: border-box;
}

.userImage img {
  border-radius: 50%;
  margin-left: 20px;
  overflow: hidden;
  width: 60px !important;
  height: 60px;
}

.content {
  padding-left: 20px;
  max-width: 80%; /* Ensure text doesn't overflow */
}

/* Optional: add a subtle shadow for the carousel effect */
.reviews ul li {
  border-radius: 10px;
  margin-right: 20px; /* Space between reviews */
  border-left: 1px solid #fff;
  display: flex;
  height: auto;
}
.user-name {
  color: #fff;
  padding-top: 20px;
  display: block;
}

@media (max-width: 768px) {
  .login {
    display: block;
  }

  .login-form {
    text-transform: capitalize;
    min-height: 300px;
    background-color: #fff;
    padding: 30px 30px;
    box-shadow: 0 5px 20px 5px rgba(0, 0, 0, 0.15);
    margin-left: 0;
    margin-top: 0;
  }
}
