.boda-stages {
    padding: 20px 0;
}
.digital-broker{
  background-color: #f1f1f1;
}
.cart{
  width: 50px;
  position: absolute;
  top: 4px;
  right: 7px;
}
.stage-list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}
.stage-scroll{
  overflow: hidden;
}
.stage-list li {
  white-space: nowrap; /* Prevent text wrapping */
  padding: 7px 20px;
  background-color: #f8f9fa;
  border-radius: 5px;
  cursor: pointer;
}
.img-section{
    width:100%;
    height: 240px;
    overflow: hidden;
}
.stage-list li.active {
    background-color: #833C99;
    color: white;
}
.card-boda {
  background-color: #fff !important;
  height: auto;
  margin-bottom: 15px;
  padding: 0;
  padding-bottom: 15px;
  overflow: hidden;
  margin: 10px 10px;
  border-radius: 8px;
  position: relative; 
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out
}
.card-boda:hover{
  box-shadow: 0 5px 20px 5px rgba(0, 0, 0, 0.15);
  transform: scale(1.02)
}

.card-boda .title {
  background-color: rgba(0, 0, 0, 0.6); /* Black with 50% opacity */
  color: #fff;
  width: 100%;
  padding: 10px;
  font-weight: 500;
  text-align: center;
  margin-top: -43px;
  position: absolute;
  z-index: 10; /* Make sure title stays on top */
  box-sizing: border-box; /* Ensure padding is included in width */
}

.card-boda .small-btn {
    position: absolute;
    top:10px;
    font-size: 13px;
    left:10px;
    border-radius: 20px;
}
/* Ensure the card doesn't have any overflow */
.image-section img {
  width: 100%;
  height: 240px !important;
  object-fit: cover; /* This ensures the image fits the container */
}
.col-md-3 {
     width:23.2%
}
.small-btn{
  height: auto !important;
}

.col-md-4 {
    width:30.9%;
 }
.db-attri{
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
}

.btn-outline-primary{
    border:1px solid #833C99;
    color: #833C99;
    margin-right: 10px;
}

.active{
  background-color: #833C99 !important;
  border-color: #833C99 !important;
}

  .prop-type{
    list-style: none;
    display: flex;
    justify-content: center;
  }

  .prop-type li{
    padding: 20px;
    color: #833C99;
    font-size: 16px;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 600;
    border-radius: 4px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 10px;
   
  }
  .prop-type li:hover{
    background-color: #f3e5f5;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .pactive{
    border-bottom: 3px solid #833C99;
    background-color: #f3e5f5;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .price, .prev-price {
    margin: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 1.2rem;
    line-height: 1.6;
    padding: 7px 20px;
    padding-bottom: 0;
    letter-spacing: 0.01071em;
    color: #833C99;
    font-weight: 600;
}
.prev-price{
  text-decoration: line-through;
  padding-top: 10px;
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    color: #757575;
}
.price-section{
  width:100%;
  display: flex;
  justify-content: space-between;
}
.details-section{
  width: 100%;
  padding: 2px 20px;
  font-size: 14px;
  margin-top: 5px;
  display: flex;
  min-height: 40px;
}
.details-section p{
  display: flex;
  width: 100%;
 }
.location-icon{
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.25rem;
  color: #833C99;
  margin-right: 8px;
}
 .details-section img{
    width: 40px;
 }

.card-boda h6 {
  text-align: center;
  padding: 0;
  margin: 0;
}
.img-sec{
    display: block;
    height: 3.2em;
    margin-right: px;
    width: 29px;
}
.loc-det{
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    color: #757575;
    text-align: center;
}
.bottom-section{
    display: flex;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    color: #757575;
    letter-spacing: 0.01071em;
    padding-top: 10px;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
    border-top: 1px solid #e0e0e0;
    gap: 16px;
    margin-left: 20px;
    margin-right: 20px;
}
.bottom-section span{
  display: flex;
}
.view-more{
  display: flex;
  justify-content: center;
}

.want-card{
  background-color: #dadada;
  min-height: 100px;
  padding: 30px;
  border-radius: 5px;
  margin-top: 20px;
}
.prop-card{
  white-space: nowrap; /* Prevent text wrapping */
    padding: 55px 30px;
    min-width:170px;
    height: 150px;
    background-color: #000;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    margin-left: 20px;
    background-image:  linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url("../../assets/images/AfricaFood.jpg");
    background-size: 100%;
}
.prop-group{
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.prop-card {
   color:#fff;
   display: flex;
   text-decoration: none;
   margin-left: 0;
}
.prop-card:hover{
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-bottom: 5px solid #E2C032;
  -webkit-transition: all 0.3s ease;
  transition: all 0.8s ease;
  transform: scale(1.05);
}
.prop-card h4{
  text-align: center;
}
.prop-class{
  margin-bottom: 20px;
}
.prop-section{
  padding:40px;
  display:flex;
  flex-direction: column;
}

.broker-type {
  display: flex;
  list-style: none;
  justify-content: center;
}

.broker-type li {
  padding: 10px 20px;
  color: #333;
  background-color: #fff;
  border: 1px solid #fff;
  margin-left: 10px;
  border-radius: 10px;
  cursor: pointer;
}

.broker-type .active {
  font-weight: 500;
  color: #fff !important;
}

.broker-type li:hover {
  border: 1px solid #cbad2d;
  color: #cbad2d;
  cursor: pointer;
}

.nav-tabs .nav-link {
  color: #4b5563;
  border: none;
  border-bottom: 2px solid transparent;
  padding: 0.5rem 1rem;
  margin-right: 1rem;
  font-weight: 500;
}

.nav-tabs .nav-link:hover {
  border-color: transparent;
  border-bottom: 2px solid #e5e7eb;
}

.nav-tabs .nav-link.active {
  color: var(--bs-primary);
  border: none;
  border-bottom: 2px solid var(--bs-primary);
  background-color: transparent;
}

.card {
  border: none;
  border-radius: 0.5rem;
  overflow: hidden;
}

.listing-swiper {
  height: 12rem;
}

.listing-swiper .swiper-button-next,
.listing-swiper .swiper-button-prev {
  background-color: rgba(255, 255, 255, 0.9);
  width: 28px;
  height: 28px;
  border-radius: 50%;
}

.listing-swiper .swiper-button-next:after,
.listing-swiper .swiper-button-prev:after {
  font-size: 12px;
  font-weight: bold;
}

.listing-swiper .swiper-pagination-bullet {
  background: white;
  opacity: 0.7;
}

.listing-swiper .swiper-pagination-bullet-active {
  background: var(--bs-primary);
  opacity: 1;
}