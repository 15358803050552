.agent-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .upload-box {
    border: 2px dashed #ccc;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .upload-box label {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #6c757d;
  }
  