.filters-container {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  overflow: hidden;
  justify-content: center;
}

.filters {
/*  flex: auto;*/
  overflow-x: auto;
  scrollbar-width: none; /* Hide scrollbar for Firefox */
  -ms-overflow-style: none; /* Hide scrollbar for IE/Edge */
  padding: 8px 0; /* Add padding for better spacing */
}

.filters::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome/Safari */
}

/* Style for filter pills */
.nav-pills .nav-link {
  border-radius: 30px; /* Pill shape */
  padding: 8px 16px;
  margin: 0 4px;
  background-color: #fff; /* Default background */
  color: #333; /* Default text color */
  border: 1px solid #ddd; /* Border for better visibility */
  font-size: 14px;
  transition: background-color 0.3s, color 0.3s, transform 0.2s;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;

}

/* Active filter pill */
.nav-pills .nav-link.active {
  background-color: #833C99; /* Active background */
  color: white; /* Active text color */
  border-color: #833C99; /* Active border color */
}

/* Hover effect for filter pills */
.nav-pills .nav-link:hover {
  background-color: #8d3ea5; /* Hover background */
  color: white; /* Hover text color */
  border: none;
  transform: scale(1.01); /* Slight zoom effect */
}

/* Scroll buttons */
.scroll-button {
  background: rgb(75, 15, 93, 0.8);
  color: white;
  border: none;
  border-radius: 50%;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background 0.2s, transform 0.2s;
  z-index: 12;
}

.scroll-button:hover {
  background: rgb(75, 15, 100);
  transform: scale(1.1);
}

.scroll-button.left {
  position: absolute;
  left: 10px;
  z-index: 5;
}

.scroll-button.right {
  position: absolute;
  right: 10px;
  z-index: 5;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .filters {
    scroll-snap-type: x mandatory; /* Enable snapping for swiping */
  }

  .nav-pills .nav-link {
    scroll-snap-align: start; /* Snap each pill to the start */
  }

  .scroll-button {
    display: none; /* Hide buttons on mobile */
  }
}